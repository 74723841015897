// 引入路由和storage工具函数
import storage from './storage'
import router from "@/router"
import {Message} from "element-ui"

let lastTime = new Date().getTime()
let currentTime = new Date().getTime()
let timeOut = 2 * 3600 * 1000  //设置超时时间: 5分钟

window.onload = function () {
    /*let config = JSON.parse(localStorage.getItem('config'))
    if (config && config.login_timeout) {
        timeOut = parseInt(config.login_timeout) * 60 * 1000
    }*/
    window.document.onmousedown = function () {
        storage.setItem("lastTime", new Date().getTime())
    }
};

function checkTimeout() {
    currentTime = new Date().getTime()		//更新当前时间
    lastTime = storage.getItem("lastTime");
    if (currentTime - lastTime >= timeOut) { //判断是否超时
        // 清除storage的数据(登陆信息和token)
        storage.clear()
        // 跳到登陆页
        if(router.currentRoute.name == 'Login') return // 当前已经是登陆页时不做跳转
        Message.warning("登录信息已过期，请重新登录")
        setTimeout(() => {
            router.push('/login')
        }, 500)
    }
}

export default function () {
    /* 定时器 间隔30秒检测是否长时间未操作页面 */
    window.setInterval(checkTimeout, 30000);
}

export const setLoginTimeOut = (m) => {
    timeOut = parseInt(m) * 60 * 1000
}