import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import index from '../views/index.vue'
import h5 from '../views/h5/index'
Vue.use(VueRouter)

const routes = [
	{
		path: '/p/:id',
		name: 'wjwelcome',
		// component: resolve => require(['../views/h5/welcome.vue'], resolve)
		component: resolve => require(['../views/h5/welcomeBeforeSession.vue'], resolve)
	},
	{ path: '/login', name: 'Login', component: Login },
	{ path: '/dbcheck', name: 'dbcheck', component: resolve => require(['../views/sys/dbcheck'], resolve) },
	{ path: '*', redirect: "/home" },
	{
		path: '/index',
		name: 'index',
		component: index,
		children: [
			{ path: '/home', component: resolve => require(['../views/home/home'], resolve) },
			{ path: '/iframe', component: resolve => require(['../views/sys/sys_iframe.vue'], resolve) },
			{ path: '/sys_menu', component: resolve => require(['../views/sys/sys_menu'], resolve) },
			{ path: '/sys_role', component: resolve => require(['../views/sys/sys_role'], resolve) },
			{ path: '/sys_teacherDept', component: resolve => require(['../views/sys/sys_teacherDept'], resolve) },
			// {	path: '/sys_deptRole',	component: resolve => require(['../views/sys/sys_deptRole'], resolve)}, 
			// { 	path: '/sys_deptUser', 	component: resolve => require(['../views/sys/sys_deptUser'], resolve) },
			{ path: '/teacher', component: resolve => require(['../views/sys/teacher'], resolve) },
			{ path: '/tchdetail', component: resolve => require(['../views/sys/tchDetail'], resolve) },
			// {	path: '/sys_studentDept',	component: resolve => require(['../views/sys/sys_studentDept'], resolve)},
			{ path: '/student', component: resolve => require(['../views/sys/student'], resolve) },
			{ path: '/stubzr', component: resolve => require(['../views/sys/studentbzr'], resolve) },
			{ path: '/directory', component: resolve => require(['../views/sys/directory'], resolve) },
			{ path: '/sys_modules', component: resolve => require(['../views/sys/sys_module'], resolve) },
			// {	path: '/sys_module_roles',	component: resolve => require(['../views/sys/sys_module_roles'], resolve)},
			// {	path: '/app',	component: resolve => require(['../views/apps/app'], resolve)},
			// {	path: '/serveConfig',	component: resolve => require(['../views/sys/serveConfig'], resolve)},
			// {	path: '/courseConfig',	component: resolve => require(['../views/sys/courseConfig'], resolve)},
			{ path: '/init', component: resolve => require(['../views/sys/theme'], resolve) },
			{ path: '/stu_sign', component: resolve => require(['../views/sign/stu_sign'], resolve) },
			{ path: '/datadk', component: resolve => require(['../views/sign/daka_data'], resolve) },
			{ path: '/dkyj', component: resolve => require(['../views/sign/dkyj'], resolve) },
			{ path: '/workday', component: resolve => require(['../views/sign/workday'], resolve) },
			{ path: '/shixisp', component: resolve => require(['../views/students/shixisp'], resolve) },
			{ path: '/stuleave', component: resolve => require(['../views/stuleave/list'], resolve) },
			{ path: '/notify', component: resolve => require(['../views/notify/list'], resolve) },
			{ path: '/notifyedit', component: resolve => require(['../views/notify/articleedit'], resolve) },
			{ path: '/reportWeek', component: resolve => require(['../views/report/week.vue'], resolve) },
			{ path: '/reportMonth', component: resolve => require(['../views/report/month.vue'], resolve) },
			{ path: '/reportForm', component: resolve => require(['../views/report/reportForm.vue'], resolve) },
			{ path: '/xfconfig', component: resolve => require(['../views/xunfang/xfconfig.vue'], resolve) },
			{ path: '/xflist', component: resolve => require(['../views/xunfang/xflist.vue'], resolve) },
			{ path: '/xfListLeader', component: resolve => require(['../views/xunfang/xflist_leader.vue'], resolve) },
			{ path: '/xfsp', component: resolve => require(['../views/xunfang/xfsp.vue'], resolve) },
			{ path: '/returnToSchool', component: resolve => require(['../views/shixiRecord/returnToSchool.vue'], resolve) },
			{ path: '/weiji', component: resolve => require(['../views/shixiRecord/weiji.vue'], resolve) },
			{ path: '/sxjhlist', component: resolve => require(['../views/sxjl/sxjhlist.vue'], resolve) },
			{ path: '/sxzjlist', component: resolve => require(['../views/sxjl/sxzjlist.vue'], resolve) },
			{ path: '/datatj', component: resolve => require(['../views/datatj/home.vue'], resolve) },
			{ path: '/datatj_daka', component: resolve => require(['../views/datatj/com/daka.vue'], resolve) },
			{ path: '/datatj_shixi', component: resolve => require(['../views/datatj/com/shixi.vue'], resolve) },
			{ path: '/datatj_zhoubao', component: resolve => require(['../views/datatj/com/zhoubao.vue'], resolve) },
			{ path: '/datatj_yuebao', component: resolve => require(['../views/datatj/com/yuebao.vue'], resolve) },
			{ path: '/yxsxssj_list', component: resolve => require(['../views/yxsxssj/yxsxssjlist.vue'], resolve) },
			{ path: '/xsftjl_list', component: resolve => require(['../views/xsftjl/xsftjllist.vue'], resolve) },
			{ path: '/sxgldxal_list', component: resolve => require(['../views/sxgldxal/sxgldxallist.vue'], resolve) },
			{ path: '/dwkpyj_list', component: resolve => require(['../views/dwkpyj/dwkpyjlist.vue'], resolve) },
			{ path: '/files', component: resolve => require(['../views/filestore/files.vue'], resolve) },
			{ path: '/ivst_plan', component: resolve => require(['../views/ivst/plan.vue'], resolve) },
			{ path: '/plan_bzr', component: resolve => require(['../views/ivst/plan_bzr.vue'], resolve) },
			{ path: '/ivst_list', component: resolve => require(['../views/ivst/list.vue'], resolve) },
			{ path: '/ivst_edit/:id', component: resolve => require(['../views/ivst/edit.vue'], resolve) },
			{ path: '/reportMajor', component: resolve => require(['../views/ivst/reportMajor.vue'], resolve) },
			{ path: '/jixiaoset', component: resolve => require(['../views/jixiao/jx_set.vue'], resolve) },
			{ path: '/jxstulist', component: resolve => require(['../views/jixiao/jxstulist.vue'], resolve) },
			{ path: '/jxstu_graduatelist', component: resolve => require(['../views/jixiao/jxstu_graduatelist.vue'], resolve) },
			{ path: '/jxtchlist', component: resolve => require(['../views/jixiao/jxtchlist.vue'], resolve) },
			{ path: '/banner', component: resolve => require(['../views/sys/banner.vue'], resolve) },
			{ path: '/guide', component: resolve => require(['../views/guide/guide.vue'], resolve) },
			{ path: '/guideform', component: resolve => require(['../views/guide/guideform.vue'], resolve) },
			{ path: '/sxqy', component: resolve => require(['../views/sxqy/sxqy.vue'], resolve) },
			{ path: '/91job', component: resolve => require(['../views/91job/91job'], resolve) },
			{ path: '/bzrws', component: resolve => require(['../views/91job/bzrws'], resolve) },
			{ path: '/lhxy', component: resolve => require(['../views/91job/lhxy'], resolve) },
			{ path: '/ws_tj', component: resolve => require(['../views/91job/ws_tj'], resolve) },
			{ path: '/majortj', component: resolve => require(['../views/91job/major_tj'], resolve) },
			{ path: '/tptj', component: resolve => require(['../views/91job/tptj'], resolve) },
			{ path: '/jyjtable', component: resolve => require(['../views/91job/jyj_table'], resolve) },
			{ path: '/xuexin', component: resolve => require(['../views/91job/xuexin'], resolve) },
			{ path: '/sytable', component: resolve => require(['../views/91job/sytable'], resolve) },
			{ path: '/jytable', component: resolve => require(['../views/91job/jytable'], resolve) },
			{ path: '/byqstable', component: resolve => require(['../views/91job/byqstable'], resolve) },
			{ path: '/guopingtai', component: resolve => require(['../views/91job/guopingtai'], resolve) },
			{ path: '/form_download', component: resolve => require(['../views/download/download'], resolve) },
			{ path: '/xxcjconfig', component: resolve => require(['../views/students/xxcjConfig'], resolve) },
			{ path: '/back_to_school', component: resolve => require(['../views/backtoschool/backtoschool'], resolve) },
			{ path: '/check_record', component: resolve => require(['../views/checkrecord/list'], resolve) },
			{ path: '/special_material_jan', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_feb', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_mar', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_apr', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_may', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_jun', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_jul', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_aug', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_sep', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_oct', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_nov', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/special_material_dec', component: resolve => require(['../views/njsyzxcl/list'], resolve) },
			{ path: '/stuScore', component: resolve => require(['../views/stuScore/stuScore'], resolve) },
			{ path: '/collectInformation', component: resolve => require(['../views/collectInformation/collectInformation'], resolve) },
			{ path: '/tchrSituation', component: resolve => require(['../views/jixiao/tchrSituation'], resolve) },
			{ path: '/jyzdlist', component: resolve => require(['../views/students/jyzd'], resolve) },
			{ path: '/socialPractice', component: resolve => require(['../views/shijianAshixi/social_practice'], resolve) },
			{ path: '/sxEvaluation', component: resolve => require(['../views/shijianAshixi/sx_evaluation'], resolve) },
			{ path: '/sxIdentify', component: resolve => require(['../views/shijianAshixi/sx_identify'], resolve) },
			{ path: '/schteacher_ledger', component: resolve => require(['../views/wxjd/ledger.vue'], resolve) },
			{ path: '/sxjd_list', component: resolve => require(['../views/wxjd/sxjd.vue'], resolve) },
			{ path: '/postBDZ', component: resolve => require(['../views/ems/postBDZ.vue'], resolve) },
			{ path: '/postFiles', component: resolve => require(['../views/ems/postFiles.vue'], resolve) },
			{ path: '/fieldworkBatch', component: resolve => require(['../views/sxfp/fieldworkBatch.vue'], resolve) },
			{ path: '/stuAllocate', component: resolve => require(['../views/sxfp/stuAllocate.vue'], resolve) },
			{ path: '/sxRecord', component: resolve => require(['../views/sxfp/sxRecord.vue'], resolve) },
			{ path: '/keshiReport', component: resolve => require(['../views/medicalSchool/keshiReport.vue'], resolve) },
			{ path: '/deptInternshipSum', component: resolve => require(['../views/deptInternshipSum/deptInternshipSum.vue'], resolve) },

		]
	},
	{
		path: "/jobManage",
		component: index,
		redirect: "/job_major",
		children: [
			{ path: "/job_major", component: resolve => require(['../views/JobManage/dictionary/major.vue'], resolve) },
			{ path: "/job_jobType", component: resolve => require(['../views/JobManage/dictionary/jobType.vue'], resolve) },
			{ path: "/job_industryType", component: resolve => require(['../views/JobManage/dictionary/industryType.vue'], resolve) },
			{ path: '/zp_xxzph', component: resolve => require(['../views/JobManage/qygl/xxzph.vue'], resolve) },
			{ path: '/zp_zwxxlb', component: resolve => require(['../views/JobManage/qygl/zwxxlb.vue'], resolve) },
			{ path: '/qytz_edit', component: resolve => require(['../views/JobManage/qygl/qytz_edit.vue'], resolve) },
			{ path: '/zp_qytz', component: resolve => require(['../views/JobManage/qygl/qytz.vue'], resolve) },
			{ path: '/zp_sjfx', component: resolve => require(['../views/JobManage/qygl/sjfx.vue'], resolve) },
			{ path: '/zp_rzqylb', component: resolve => require(['../views/JobManage/qygl/rzqylb.vue'], resolve) },
			{ path: '/xsjl', component: resolve => require(['../views/JobManage/jlgl/xsjl.vue'], resolve) },
			{ path: '/tdjl', component: resolve => require(['../views/JobManage/jlgl/tdjl.vue'], resolve) },
			{ path: '/xysxh', component: resolve => require(['../views/JobManage/qygl/xysxh.vue'], resolve) },
			{ path: '/ent_datatj', component: resolve => require(['../views/JobManage/qygl/entdatatj.vue'], resolve) },
			{ path: '/qyxjh', component: resolve => require(['../views/JobManage/qygl/qyxjh.vue'], resolve) },
			{ path: '/xjhinfo', component: resolve => require(['../views/JobManage/qygl/qyxjhinfo.vue'], resolve) },
			{ path: '/stuJobHunt', component: resolve => require(['../views/JobManage/jlgl/stuJobHunt.vue'], resolve) },
			{ path: '/employmentWorkSumary', component: resolve => require(['../views/JobManage/employmentWorkSumary/employmentWorkSumary.vue'], resolve) },
		]
	},
	{
		path: "/exam",
		component: index,
		redirect: "/exam_list",
		children: [
			{ path: "/exam_list", component: resolve => require(['../views/exam/plan.vue'], resolve) },
			{ path: "/paper", component: resolve => require(['../views/exam/list.vue'], resolve) },
			{ path: "/exam_edit/:id", component: resolve => require(['../views/exam/edit.vue'], resolve) },

		]
	},
	{
		path: "/njjs",
		component: index,
		redirect: "/home",
		children: [
			{ path: "/njjs_stu", component: resolve => require(['../views/njjs_stu/students'], resolve) },
			{ path: "/njjs_tj", component: resolve => require(['../views/njjs_stu/jobtj'], resolve) },


		]
	},
	{
		path: "/graduateFollowUp",
		name:'毕业跟踪调查',
		component: index,
		redirect: "/home",
		children: [
			{ path: '/bygz_alumniInfo', name: '校友信息', component: resolve => require(['../views/graduateFollowUp/alumniInfo/alumniInfo'], resolve) },
			{ path: '/bygz_suggestion', name: '校友留言', component: resolve => require(['../views/graduateFollowUp/suggest/suggest'], resolve) },
			{ path: '/bygz_activity', name: '校友活动', component: resolve => require(['../views/graduateFollowUp/activity/activity'], resolve) },
			{ path: '/bygz_forum', name: '校友问答', component: resolve => require(['../views/graduateFollowUp/forum/forum'], resolve) },
			{ path: '/bygz_news1', name: '校内新闻', component: resolve => require(['../views/graduateFollowUp/news/news1'], resolve) },
			{ path: '/bygz_news2', name: '通知公告', component: resolve => require(['../views/graduateFollowUp/news/news2'], resolve) },
			{ path: '/bygz_campus', name: '校园风景', component: resolve => require(['../views/graduateFollowUp/campus/campus'], resolve) },
			{ path: '/bygz_classPhoto', name: '班级相册', component: resolve => require(['../views/graduateFollowUp/classPhoto/classPhoto'], resolve) },
		]
	},
	//手机端的页面放这里
	{
		path: "/h5",
		component: h5,
		redirect: "/h5_plan_list",
		children: [
			{ path: '/h5_plan_list', component: resolve => require(['../views/h5/planlist.vue'], resolve) },
			{ path: '/h5_ivst_plan', component: resolve => require(['../views/h5/ivstplan.vue'], resolve) },
			{ path: '/h5_ivst', component: resolve => require(['../views/h5/ivst.vue'], resolve) },
			{ path: '/quxiang', component: resolve => require(['../views/h5/quxiang.vue'], resolve) },
			{ path: '/h5xxcj', component: resolve => require(['../views/h5/xxcj.vue'], resolve) },
			{ path: '/jyzd', component: resolve => require(['../views/h5/shixizhidao.vue'], resolve) },
			{ path: '/h5_exam_list', component: resolve => require(['../views/h5/examlist.vue'], resolve) },
			{ path: '/njs', component: resolve => require(['../views/h5/njjsstu.vue'], resolve) },
			{ path: '/ivstchs', component: resolve => require(['../views/h5/ivstchs.vue'], resolve) },
			{ path: '/ems_postFilesRedirect', remark: '小程序直接获取ems信息无需输入学号/姓名', component: resolve => require(['../views/h5/ems_postFiles.vue'], resolve) },
			{ path: '/ems_postFilesWithCover', remark: '公众号/网页带学号/姓名查询封面', component: resolve => require(['../views/h5/ems_postFilesWithCover.vue'], resolve) },
			//毕业跟踪-问卷调查
			{
				path: '/bygz_ivst',
				name: 'bygz_ivst',
				component: resolve => require(['../views/graduateFollowUp/h5/ivst'], resolve),
			}, {
				path: '/bygz_p/:id',
				name: 'bygz_ivstplan',
				component: resolve => require(['../views/graduateFollowUp/h5/ivstplan'], resolve),
			},
		]
	}
]



const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}


//路由进入之前检查是否登录
router.beforeEach((to, from, next) => {
	let auth = $request("auth"); //检查url是否带有登录字符串
	let whiteList = ['/login', '/p/', '/h5_plan_list', '/h5_ivst_plan', '/h5_ivst', '/quxiang', '/njs', '/ivstchs',
		'/ems_postFilesRedirect', '/ems_postFilesWithCover',
		'/bygz_ivst', '/bygz_p/']
	if (auth) {
		window.localStorage.setItem("auth", auth); //将登录字符串保存到缓存
		//移除url的auth并刷新页面
		let localUrl = window.location.href.replace(auth, '').replace("?auth=", '').replace("&auth=", '')
		window.location.href = localUrl
		window.location.reload()
	} else { //跳转统一登录
		if (window.localStorage.getItem("auth")) { //检查本地是否登录
			next()
			// if (to.query.pmid == 215) {
			// 	next()
			// } else {
			// 	let query = to.query
			// 	query.pmid = 215
			// 	next({
			// 		path: to.path,
			// 		query: query
			// 	})
			// }

		} else {
			console.log(to.path)
			if (whiteList.indexOf(to.path) >= 0 || to.path.indexOf("/p/") >= 0 || to.path.indexOf("/bygz_p/") >= 0) {
				next()
			} else {
				next({ path: "/login" })
			}
		}
	}
})



export default router
