
let r_a_w_js = require('crypto-js');
let mykey = "", loading = false;
let getSelfKey = async opt => {
	return new Promise((resolve, reject) => {

		if (!loading && !mykey) {
			loading = true
			$.get("https://api-saas.qushixi.org.cn/api/lcrtm", e => {
				myra_wjs.decrypt0(e.data).then(r => {
					mykey = r
					resolve(r)
				})
			})
		} else {
			if (mykey) {
				resolve(mykey)
			} else {
				let t = setInterval(() => {
					
					if (mykey) {
						clearInterval(t)
						resolve(mykey)
					}
				}, 200);
			}
		}

	})
}
let generatekey = (app) => {
	return new Promise(async (resolve, reject) => {
		if (app) {
			resolve("qsx.qushixi.cn");
		} else {
			let r = await getSelfKey()

			resolve(r)
		}
	})

};
let myra_wjs = {
	//随机生成指定数量的16进制key



	//加密
	encrypt: async (word) => {
		let keyStr = await generatekey(); //判断是否存在ksy，不存在就用定义好的key
		let v = String.fromCharCode(65) + String.fromCharCode(69) + String.fromCharCode(83)
		let encJson = r_a_w_js[v].encrypt(word, keyStr).toString();
		//对加密数据进行base64处理, 原理：就是先将字符串转换为utf8字符数组，再转换为base64数据
		let encData = r_a_w_js.enc.Base64.stringify(r_a_w_js.enc.Utf8.parse(encJson));

		return new Promise(resolve => {
			resolve(encData)
		})
	},
	//解密
	decrypt: async (word) => {
		let keyStr = await generatekey();
		return new Promise(resolve => {
			try {
				let decData = r_a_w_js.enc.Base64.parse(word).toString(r_a_w_js.enc.Utf8);
				//解密数据
				let decJson = r_a_w_js.AES.decrypt(decData, keyStr).toString(r_a_w_js.enc.Utf8);
			
				resolve(decJson)
			} catch (error) {
				resolve("")
			}

		})

	},
	//解密私钥
	decrypt0: async (word) => {
		let keyStr = await generatekey("me");

		let decData = r_a_w_js.enc.Base64.parse(word).toString(r_a_w_js.enc.Utf8);
		//解密数据
		let decJson = r_a_w_js.AES.decrypt(decData, keyStr).toString(r_a_w_js.enc.Utf8);
		return new Promise(resolve => {
			resolve(decJson)
		})
	} 

}

export default myra_wjs
