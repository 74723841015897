<template>
	<div class="page">
		<sysmenu></sysmenu>
		<div :class="['rightcontent']">
			<div :class="['rightpage']">
				<router-view />
			</div>
		</div>

	</div>
</template>

<script>
import sysmenu from './menu/menu.vue'
import {setLoginTimeOut} from "@/utils/astrict";

export default {
	components: {
		sysmenu


	},
	data() {
		return {
			showmenu: 1,
			mainbg: ""
		}
	},
	mounted() {
		this.getConfig();
		this.getTeacher()

		console.log(3333, window.location.href.indexOf('showIframe'))
		if (window.location.href.indexOf('showIframe') > -1) {
			let iframeUrl = decodeURIComponent(this.$GetQueryString('iframeUrl'))
			let nowbMenu = decodeURIComponent(this.$GetQueryString('nowbMenu'))
			let nowsMenu2 = decodeURIComponent(this.$GetQueryString('nowsMenu2'))
			this.$store.dispatch("setShowIframe", 1)
			this.$store.dispatch("setIframeUrl", iframeUrl)
			this.$router.push({
				path: "/iframe",
				query: {
					iframeUrl: iframeUrl,
					t: Date.now(),
					nowbMenu: nowbMenu,
					nowsMenu2: nowsMenu2,
				}
			})
			console.log(3333, iframeUrl, nowbMenu, nowsMenu2)

		} else {
			this.$store.dispatch("setShowIframe", 0)
		}
		

	},
	methods: {
		hideMenu() {
			if (this.$store.state.leftmenu) {
				this.$store.dispatch("showSmenu", 0)
			} else {
				this.$store.dispatch("showSmenu", 1)
			}

		},
		getTeacher() {
			this.$http.post("/api/get_teacher_info").then(res => {
				this.$store.dispatch("setTeacher", res.data)
			})
		},
		getConfig() {
			if (localStorage.getItem("config")) {
				let config = JSON.parse(localStorage.getItem("config"))
				this.$store.dispatch("setConfig", config)
				if (config.favico) {
					var link = document.createElement('link');
					link.type = 'image/x-icon';
					link.rel = 'shortcut icon';
					link.href = config.favico;
					document.getElementsByTagName('head')[0].appendChild(link);
				}
				if (config.appname) {
					document.title = config.appname
				}
			}
			this.$http.post("/api/sch_configs").then(res => {
				let config = {}
				for (let k of res.data) {
          if (k.ktype == 'sync_data') {
            k.kvalue = parseInt(k.kvalue)
          }
					config[k.ktype] = k.kvalue
				}
				if (config.favico) {
					var link = document.createElement('link');
					link.type = 'image/x-icon';
					link.rel = 'shortcut icon';
					link.href = config.favico;
					document.getElementsByTagName('head')[0].appendChild(link);
				}
				if (config.appname) {
					document.title = config.appname
				}
			

				this.$store.dispatch("setConfig", config)
				localStorage.setItem("config", JSON.stringify(config))

        if (config.login_timeout) {
          setLoginTimeOut(config.login_timeout)
        }
			})
		},

	}
}
</script>
<style scoped>
.page {
	position: relative;
	overflow: hidden;
	background-size: cover;
}

.rightcontent {
	border-top: 60px solid transparent;
	height: 100%;
	box-sizing: border-box;
	transition: margin .3s;
	margin-left: 182px;
	margin-right: 0%;
	border-radius: 4px;

}

.expand {
	margin-left: 0%;

}

.rightpage {

	/*padding: 10px;*/
	background-color: #fafbfc;
	height: 100%;
	overflow: auto;
	box-sizing: border-box;
	border-radius: 4px;
	padding-left: 40px;
	padding-right: 10px;

}

.expand2 {
	padding-left: 10px;
}

.expandbtn {

	width: 50px;
	height: 0;
	border-top: 20px solid rgba(118, 118, 118, 0.3);
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	position: absolute;
	top: 48%;
	left: 166px;
	color: #f6f6f6;
	transition: all 0.2s;
	transform-origin: top center;
	transform: rotateZ(-90deg) scaleY(1);
	cursor: pointer;
	z-index: 3;

}

.hideexp {
	left: -20px;
}

.btnarrow {
	position: absolute;
	top: -20px;
	left: 16px;
}

.expandbtn:hover {
	border-top: 20px solid rgba(118, 118, 118, 0.6);
	transform: rotateZ(-90deg) scaleY(1.1);
	color: #ccc;

}

</style>
