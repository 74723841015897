import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import aes from './utils/aes.js'
Vue.config.productionTip = false
import axios from "axios"
Vue.prototype.$http = axios

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '../public/css/element-#3378FA/index.css'

Vue.use(ElementUI);


import echarts from "echarts"
Vue.prototype.$echarts = echarts

import Print from 'vue-print-nb'
Vue.use(Print);

import '../public/css/global.less'

import '../public/js/tableFit'

import _lo from 'lodash'
Vue.prototype._lo = _lo

import Moment from 'moment'
Vue.prototype.$moment = Moment

import Astrict from "@/utils/astrict";
Vue.use(Astrict)

// import 'viewerjs/dist/viewer.css'
// import Viewer from 'v-viewer'
// Vue.use(Viewer)

// 巡访地址
import AMap from 'vue-amap'
Vue.use(AMap)
// 初始化vue-amap
AMap.initAMapApiLoader({
	// 高德key
	key: '0b5d4c06598b47042b9b5257889f7e26',
	// 插件集合 （插件按需引入）
	plugin: ['AMap.Geolocation', 'AMap.Geocoder']
});

Vue.prototype.$globalLoading = function () {
	const loading = this.$loading({           // 声明一个loading对象
		lock: true,                             // 是否锁屏
		text: '加载中',                         // 加载动画的文字
		spinner: 'el-icon-loading',             // 引入的loading图标
		background: 'rgba(255, 255, 255, 0.4)',       // 背景颜色
		target: '.el-table, .table-flex, .region, .loading, .pageContainer',       // **需要遮罩的区域，这里写要添加loading的选择器**
		fullscreen: true,
		customClass: 'loadingclass'             // **遮罩层新增类名，如果需要修改loading的样式**
	})
	setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
		loading.close();                        // 关闭遮罩层
	}, 3000)
	return loading;
}

// 拖拽弹框
Vue.prototype.dropDialog = function (el) {
	let dragDom = el.querySelector('.el-dialog');
	let dragDomHeader = el.querySelector('.el-dialog__header');
	let offsetLeft = 0;
	let offsetTop = 0;
	dragDomHeader.style.cursor = 'move';
	dragDomHeader._isDrag = false;
	// 鼠标按下事件
	// onmousedown事件绑定之所以在el-dialog__header上，是因为绑定在el-dialog时弹框中的表单会无法编辑
	dragDomHeader.onmousedown = (e) => {
		dragDomHeader._isDrag = true;
		// 鼠标相对元素的位置
		dragDomHeader._startX = e.clientX - offsetLeft;
		dragDomHeader._startY = e.clientY - offsetTop;
		e.preventDefault();
	};
	document.onmousemove = (e) => {
		if (dragDomHeader._isDrag) {
			// 鼠标的位置减去鼠标相对元素的位置得到元素的位置
			let _endX = e.clientX - dragDomHeader._startX;
			let _endY = e.clientY - dragDomHeader._startY;
			offsetLeft = _endX
			offsetTop = _endY
			dragDom.style.left = _endX + 'px';
			dragDom.style.top = _endY + 'px';
		}
	};
	document.onmouseup = () => {
		dragDomHeader._isDrag = false;
	};
}


//正式版pdf导出
Vue.prototype.$pdfUrl = 'http://pdf.qushixi.org.cn'
// Vue.prototype.$pdfUrl = 'http://pdf-dev.qushixi.org.cn'
// Vue.prototype.$pdfUrl = 'http://127.0.0.1:30021'

//获取用户token
let token = null;
let getToken = async _ => {
	if (localStorage.getItem("auth")) {//从session中取到token后，再解密
		token = await aes.decrypt(localStorage.getItem("auth"))
	}
	return new Promise(resolve => {
		resolve(token)
	})
}
Vue.prototype.$getToken = getToken

axios.interceptors.request.use(async req => {

	//生成 c s f r 校验
	let v = String.fromCharCode(90 + 9) + String.fromCharCode(100 + 15) + String.fromCharCode(100 + 7 * 2) +
		String.fromCharCode(51 * 2)
	let c = {}
	let d = new Date()
	c[v] = d.getTime() + "_" + parseInt(Math.random() * 100000);
	c[v] = await aes.encrypt(c[v])
	let hour = d.getHours(),
		min = d.getMinutes(),
		sec = d.getSeconds();
	hour < 10 ? hour = '0' + hour : true;
	min < 10 ? min = '0' + min : true;
	sec < 10 ? sec = '0' + sec : true;
	c["_" + v] = await aes.encrypt(hour + ":" + min + ":" + sec)
	req.headers[v] = c[v] + "_" + c["_" + v]



	//todo del token
	req.headers["Authorization"] = await getToken();

	let year = sessionStorage.getItem("year");
	if (year) {
		req.headers["year"] = year
	}


	if (req.url.indexOf("http") >= 0) {

	} else {
		if ( window.location.href.indexOf("test") > -1) {
			req.url = "http://api-saas-dev.qushixi.org.cn" + req.url//测试环境
		} else {
			if (window.location.href.indexOf("localhost1") > 0 || window.location.href.indexOf("192.168") > 0) {
				req.url = "http://localhost:11024" + req.url//本地开发环境
				//req.url = "http://api-saas.qushixi.org.cn" + req.url//测试环境
			} else if (window.location.href.indexOf("60.213.4.46") > 0 ) {
				//山东交通，在nginx的路径，不用加东西
			}else {
				req.url = "//api-saas.qushixi.org.cn" + req.url
			}
		}
		//山东交通内网部署在同一个nginx
		
	}
	// if (req.data) req.data.debug = 1
	if (req.data && !req.data.noloading) {
		$("#loading").show();
	}
	return req
}, error => {
	return Promise.reject(error)
});

axios.interceptors.response.use(async response => {
	$("#loading").hide();

	if (response.data.code == 401 || response.data.name == 'TokenExpiredError') {
		localStorage.clear();
		window.location.href = "/#/login"
	} else {
		let url = response.config.url
		if (url.indexOf('sys_office_info') == -1 && url.indexOf('getMiniQrcode') == -1 &&
			response.data.code && response.data.code != 200) {
			$("#errdg").html(JSON.stringify(response.data.msg)).show();
			setTimeout(() => {
				$("#errdg").hide()
			}, 3000)
			return Promise.reject(null)
		} else {
			if (response.data.raw) {

				response.data = await aes.decrypt(response.data.raw)

				try {
					response.data = JSON.parse(response.data)
				} catch (error) {

				}
			}
			return response
		}

	}
}, error => {
	console.log(error)
	if (error.response && error.response.status == 401) {
		localStorage.clear();
		window.location.href = "/#/login"
	} else {
		return Promise.reject(error)
	}

})



Vue.prototype.uploadTemp = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",

	};
	var host = 'https://mmtemp.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 || fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function (theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function () {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}


Vue.prototype.uploadOss = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI4G55gyD65iSg836oWSK4',
		signature: '7a7dfIH8bpoHXTeZxd+3xGS/g54=',
		success_action_status: "200",
		app: "qsxsaas"

	};
	var host = 'https://oss.aiwx.org.cn/upload';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {
			let resObj = res.currentTarget.responseText
			try {
				resObj = JSON.parse(resObj)
			} catch (e) {
				resObj = {}
			}
			resObj.size = fileObj.file.size;
			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
					.indexOf(
						".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function (theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function () {
						if (resObj.src) {
							resObj.src += "?s=" + this.width + "_" + this.height
						}
						fileObj.onSuccess(resObj)

					};
				};

			} else {
				fileObj.onSuccess(resObj)
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}

//上传敏感文件
Vue.prototype.uploadOssMG = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzMC0wMS0wMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI4G55gyD65iSg836oWSK4',
		signature: 'y4G7OQ8ht4ryvJxGhDz5HR4A/RY=',
		success_action_status: "200"
	};
	var host = 'https://access-limit-files.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {
			let resObj = res.currentTarget.responseText
			try {
				resObj = JSON.parse(resObj)
			} catch (e) {
				resObj = {}
			}
			resObj.size = fileObj.file.size;
			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
					.indexOf(
						".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function (theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function () {
						if (resObj.src) {
							resObj.src += "?s=" + this.width + "_" + this.height
						}
						fileObj.onSuccess(resObj)

					};
				};

			} else {
				fileObj.onSuccess(resObj)
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}

function $$parent() {
	let parent = arguments[0];
	let func = arguments[1]
	let para = []
	for (let i = 2; i < arguments.length; i++) {
		para.push(arguments[i])
	}
	return new Promise(resolve => {
		let checkParent = idx => {
			if (parent.$parent && idx < 20) {
				parent = parent.$parent
				if (parent[func] !== undefined) {
					if (typeof (parent[func]) == "function") {
						parent[func](...para)
						resolve(true)
					} else {
						if (arguments.length >= 3) {//判定是赋值
							parent[func] = arguments[2]
							resolve(parent[func])
						} else {//判定是读值
							resolve(parent[func])
						}

					}
				} else {
					checkParent(idx + 1)
				}
			} else {
				console.log("未找到：" + func)
				resolve(undefined)
			}
		}
		checkParent(0)
	}, reject => { })

}

Vue.prototype.$$parent = $$parent

Vue.prototype.$keepTwoDecimal = (num) => {
	var result = parseFloat(num);
	if (isNaN(result)) {
		return num;
	}
	result = Math.round(num * 100) / 100;
	return result;
};


Vue.prototype.isJSON = (str) => {
	if (typeof str == 'string') {
		try {
			var obj = JSON.parse(str);
			if (typeof obj == 'object' && obj) {
				return true;
			} else {
				return false;
			}

		} catch (e) {
			console.log('error：' + str + '!!!' + e);
			return false;
		}
	} else {
		return false;
	}
}

var Request = function (name) {
	var n_url = window.location.href;
	n_url = n_url.replace("/#", "");
	if (n_url.indexOf("?") > 0) {
		n_url = n_url.split("?")[1]
	}
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = n_url.match(reg);
	if (r != null) return decodeURIComponent(r[2]);
	return null;
}
Vue.prototype.$Request = Request

let GetQueryString = (name) => {
	if (window.location.href.indexOf('?') > 0) {
		let urlStr = window.location.href.split('?')[1]
		// console.log(484, urlStr)
		// 创建空对象存储参数
		let obj = {};
		// 再通过 & 将每一个参数单独分割出来
		let paramsArr = urlStr.split('&')
		for (let i = 0, len = paramsArr.length; i < len; i++) {
			// 再通过 = 将每一个参数分割为 key:value 的形式
			let arr = paramsArr[i].split('=')
			obj[arr[0]] = arr[1];
		}
		// console.log(493, obj)
		return obj[name]
	}
}
Vue.prototype.$GetQueryString = GetQueryString

// bygz使用getQuery
Vue.prototype.getQuery = (name) => {
	var n_url = window.location.href;
	n_url = n_url.replace("/#", "");
	if (n_url.indexOf("?") > 0) {
		n_url = n_url.split("?")[1]
	}
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = n_url.match(reg);
	if (r != null) return unescape(r[2]).split("#")[0];
	return null;
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
