<template>
	<div class="loginContainer flexCenter">

		<div class="formContainer flexAround">
			<div class="logoNameContainer">
				<img :src="loginleftlogo" />
			</div>
			<div class="left">
				<img :src="imgs.leftImg" />
			</div>

			<div class="right">
				<h1 class="form-signin-heading">{{ appname }}</h1>
				<div class="coricon">
					<div class="form-signin">
						<h4 style="margin-bottom: 20px;color:#317eac;">账号密码登录</h4>

						<el-form v-if="pmid != 214" :model="EditItem" label-position="top">
							<el-form-item label="手机号">
								<el-input size="small" placeholder="请输入手机号" @keyup.enter.native="login"
									v-model="username" />

							</el-form-item>
							<el-form-item label="短信验证码">
								<div class="flexStart">
									<el-input size="small" type="text" placeholder="短信验证码" @keyup.enter.native="login"
										v-model="password" style="width:60%;">
									</el-input>
									<el-button size="small" :disabled="leftSecond != 120" @click="sendSms" type="primary"
										plain>
										{{ leftSecond == 120 ? '发送验证码' : leftSecond + 'S后重发' }}
									</el-button>
								</div>
							</el-form-item>
							<el-form-item label="验证码">
								<div class="flexBetween">
									<el-input size="small" type="text" placeholder="请输入验证码" @keyup.enter.native="login"
										v-model="yzm" style="width:60%;">
									</el-input>
									<div @click="changeYzm"
										style="border: 0; width: 40%; max-height: 40px; transform: scale(0.7,0.7); transform-origin: left top;"
										v-html="yzmUrl">
									</div>
								</div>
							</el-form-item>

							<div style=" text-align: center; padding: 20px 0 0 0;">
								<el-button size="" type="primary" style="width: 100%;" @click="login">登录</el-button>
							</div>
						</el-form>

						<el-form v-if="pmid == 214" :model="EditItem" label-position="top">
							<el-form-item>
								<el-input placeholder="请输入工号" @keyup.enter.native="login" v-model="username">
									<template slot="prepend">账号</template>
								</el-input>

							</el-form-item>
							<el-form-item>

								<div class="flexStart" style="padding-top:10px">
									<el-input class="flex_1" type="password" placeholder="登录密码" @keyup.enter.native="login"
										v-model="password">
										<template slot="prepend">密码</template>
									</el-input>


								</div>


							</el-form-item>



							<el-form-item label="验证码">
								<div class="flexBetween">
									<el-input size="small" type="text" placeholder="请输入验证码" @keyup.enter.native="login"
										v-model="yzm" style="width:60%;">
									</el-input>
									<div @click="changeYzm"
										style="border: 0; width: 40%; max-height: 40px; transform: scale(0.7,0.7); transform-origin: left top;"
										v-html="yzmUrl">
									</div>
								</div>
							</el-form-item>

							<div style=" text-align: center; padding: 20px 0 0 0;">
								<el-button size="" type="primary" style="width: 100%;" @click="login">登录</el-button>
							</div>

						</el-form>



					</div>
					<div class="form-signin flexCloumnCenter">
						<h4 style="margin-bottom: 20px;color:#317eac;text-align:center;">微信扫码登录</h4>
						<div style="position: relative;width: 80%; text-align: center; margin: 0 auto;">
							<el-image :src="qrcodeUrl ? qrcodeUrl : './img/ucenter_icon_async.png'" alt=""
								style="position: unset;width: 100%;height: 80%;;"></el-image>
							<div
								style="position:absolute; width:45%; height: 45%; min-width: 80px; min-height: 80px; background-color: #fff; left: 28%; top: 26.5%; border-radius: 50%;">
								<img v-if="applogo" :src="applogo" style="width:100%;height:100%;border-radius: 50%;" />

							</div>
						</div>
					</div>
				</div>



			</div>


		</div>


		<div style="position:fixed;bottom:33px;font-size: 13px; width: 100%; left: 0; color: #fff; text-align: center;">
			Copyright © 2022-2032 实习就业综合管理平台 - Powered By manmaitek.com V2.0
		</div>

	</div>
</template>

<script>
import jiami from "../utils/aes.js"
export default {
	data() {
		return {
			pmid: 0,
			isShowModal: false,
			showbtn: false,
			showVideo: true,
			bgidx: "",
			bgvideourl: "",
			EditItem: {},
			username: "",
			password: "",
			session_id: "",
			smssession_id: "",
			yzmUrl: "",
			yzm: "",
			showQrcode: false,
			qrcodeUrl: "",
			qrcodeChecking: false,
			qrcodeTimer: -1,
			loginleftlogo: "",
			leftSecond: 120,
			loading: false,
			appname: "",
			pwdForm: {
				newPwd1: "",
				newPwd2: "",

			},
			imgs: {
				leftImg: './../../img/crumb_left.png'
			},
			applogo: ""
		}
	},
	mounted() {


		if (!window.pmid) {
			window.pmid = 1
		}
		this.pmid = window.pmid
		this.getYZM()
		this.getConfig()
		this.getQrcode()
		let scale = $(window).width() / 1920
		
		$(".inputbox").animate({ scale: scale }, 0, "")

		// let time = parseInt(new Date().getTime()/1000)+"_"+parseInt(Math.random()*1000)
		
		// let keyStr = "yGgv7%T6yBhn"; //判断是否存在ksy，不存在就用定义好的key
		
		// let md5 =require("md5")
		
		
		// this.$http.post("/api/njws_students",{app:"njws",token:md5(keyStr+"_"+time),timestamp:time})
		
	},
	methods: {
		getConfig() {


			this.$http.post("/api/sch_config", { pmid: window.pmid }).then(res => {

				for (let k of res.data) {
					switch (k.ktype) {
						case 'loginleftlogo':
							this.loginleftlogo = k.kvalue
							break;
						case 'appname':
							this.appname = k.kvalue
							document.title = k.kvalue
							break;
						case 'schoolname':
							// document.title = k.kvalue
							break;
						case 'applogo':
							this.applogo = k.kvalue
							break;
						case 'favico':
							var link = document.createElement('link');
							link.type = 'image/x-icon';
							link.rel = 'shortcut icon';
							link.href = k.kvalue;
							document.getElementsByTagName('head')[0].appendChild(link);
							break;
					}
				}
			})
		},

		getQrcode() {
			this.$http.post("/api/login_qrcode", {
				pmid: window.pmid
			}).then(res => {

				this.smssession_id = res.data.session;

				this.qrcodeUrl = res.data.url;
				if (!this.qrcodeChecking) {
					this.qrcodeChecking = true

					this.checkQrcode();
				}
			})



		},
		checkQrcode() {

			this.qrcodeTimer = setInterval(() => {

				this.$http.post("/api/check_qrcode", {
					session_id: this.smssession_id,
					noloading: 1,
					pmid: window.pmid
				}).then(res => {
					if (res.data.auth) {
						if (res.data.pmid != window.pmid) {
							this.$message({
								message: "错误的二维码",
								type: 'error',
								offset: '600'
							});
						} else {
							if (res.data && res.data.auth) {

								localStorage.setItem("auth", res.data.auth)
								clearInterval(this.qrcodeTimer)
								this.$router.push("/home")
							} else {
								if (res.data.msg) {
									this.$message({
										message: res.data.msg,
										type: 'error',
										offset: '600'
									});
								}

							}

						}

					}
				})
			}, 1000)
		},
		beforeDestroy() {
			clearInterval(this.qrcodeTimer)
		},
		getYZM() {
			this.$http.post("/api/get_login_randomcode", {
				pmid: window.pmid
			}).then(res => {
				this.session_id = res.data.data.session_id;
				this.yzmUrl = res.data.data.svg;
			})
		},
		changeYzm() {
			this.getYZM()
		},
		async login() {
			if (this.username.trim() && this.password.trim() && this.yzm.trim()) {//&& this.yzm.trim()
				let url = '/api/admin_login'
				if (this.pmid == 214) {
					url = '/api/account_login'
				}else if (this.pmid == 217) {
					url = '/api/pc_dl'
				}
				let formdata=  {
					loginname: this.username ? this.username.replace(/\s+/g, "") : '',
					mm: this.password ? this.password.replace(/\s+/g, "") : '',
					yzm: this.yzm.toLowerCase(),
					session_id: this.session_id,
					pmid: window.pmid,
					day: 7//7天后过期
				}
				
				formdata =   await jiami.encrypt(JSON.stringify(formdata))
				this.$http.post(url,{formdata:formdata}).then(res => {
					if (res.data && res.data.auth) {

						localStorage.setItem("auth", res.data.auth)
						clearInterval(this.qrcodeTimer)
						this.getRoleMenu()
						// if (!res.data.upd_pwd) {
						// 	this.isShowModal = true
						// } else {
						// 	this.$router.push("/home")
						// }

					} else {
						this.$message({
							message: res.data.msg,
							type: 'error',
							offset: '600'
						});

					}


				}, err => {

				})
			} else {
				this.$message({
					message: "好像没有填完整~",
					type: 'error',
					offset: '600'
				});
			}
		},
		sendSms() {

			if (this.leftSecond == 120) {

				const reg = /^1\d{10}$/;
				if (!this.username) {
					this.$message.error("请输入手机号~")
					return
				} else if (!reg.test(this.username)) {
					this.$message.error("手机号码格式有误！")
					return
				} else {
					if (this.loading) {
						return
					} else {
						this.loading = true
						setTimeout(() => {
							this.loading = false
						}, 3000);
						this.$http.post("/api/qsx_smsyzm", { pmid: window.pmid, phone: this.username, session_id: this.session_id }).then(ok => {

							this.$message.success("短信验证码发送成功！")
							this.leftSecond = 119;
							const timer = setInterval(() => {
								this.leftSecond--;
								if (this.leftSecond < 0) {
									this.leftSecond = 120;
									clearInterval(timer);
								}
							}, 1000);
						})
					}
				}


			}

		},
		changeLoginType() {
			this.showQrcode = !this.showQrcode
			if (this.showQrcode && !this.qrcodeUrl) {
				this.getQrcode()
			}
		},

		checkPassWord(value) {
			if (value.length < 8 || value.length > 16) { //最初级别
				this.$message.error("密码长度为8-16位")
				return false;
			}
			if (!/\d/.test(value)) { //如果用户输入的密码 包含了数字
				this.$message.error("密码必须包含数字")
				return false;
			}
			if (!/[a-z]/.test(value) && !/[A-Z]/.test(value)) { //如果用户输入的密码 包含了小写的a到z
				this.$message.error("密码必须包含大小写字母")
				return false;
			}

			var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
				regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

			if (!regEn.test(value) && !regCn.test(value)) {
				this.$message.error("密码必须包含特殊字符")
				return false;
			}

			return true


		},

		savePwd() {

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码")
				return
			}
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码")
				return
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致")
				return
			}
			if (this.checkPassWord(this.pwdForm.newPwd2)) {
				this.showbtn = true;
				this.$http.post("/api/reset_myself_pwd_first", this.pwdForm).then(ok => {
					if (ok.data) {
						this.$message.success("修改成功,即将跳转")
						setTimeout(() => {
							this.$router.push("/home")
						}, 3000)

					}
				})
			}


		},
		getRoleMenu() {

			this.$http.post("/api/getUserMenu").then(res => {
				for (let bitem of res.data) {
					if (bitem.children && bitem.children.length > 0) {
						if (bitem.children[0] && bitem.children[0].children.length > 0) {
							this.$router.push(bitem.children[0].children[0].path)

							return
						} else if (bitem.children[0].path) {
							this.$router.push(bitem.children[0].path)

							return
						}

						
					} else if (bitem.path) {
						this.$router.push(bitem.path)

						return
					}
				}

			})
		},
	}
}
</script>

<style lang="less">
.loginContainer {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-image: url(/img/bg_png.png);
	background-color: #2657D2;

	.el-form-item {
		margin-bottom: 0px;
	}

	.el-form-item__label {
		padding: 0 !important;
		line-height: unset;
		color: #999;
	}

	.formContainer {
		position: relative;
		padding: 80px 0;
		width: 70%;
		border-radius: 8px;
		background-color: #fff;
		background-image: url('./../../public/img/crumb_bottom.png');
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: left bottom;

		.logoNameContainer {
			position: absolute;
			top: 20px;
			left: 20px;
			z-index: 1000;
			width: 28%;

			img {
				width: 100%;
			}
		}

		.left {
			width: 30%;

			img {
				width: 100%;
			}
		}

		.right {
			.header {
				height: 80px;
				padding-top: 20px;

			}

			.form-signin-heading {
				font-size: 25px;
				text-align: center;
				color: #0663a2;
			}

			.coricon {
				position: relative;
				display: flex;
				justify-content: stretch;
				align-items: flex-start;
				background-color: #fff;

				.form-signin {
					position: relative;
					text-align: left;
					width: 300px;
					padding: 25px 29px 29px;
					margin: 0 auto 0;
					background-color: #fff;
					/* border: 1px solid #e5e5e5; */
					border-radius: 0px;
					box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
				}
			}

		}
	}
}







.bottom {
	width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 0;
}

.bottom img {
	width: 100%;
	vertical-align: bottom;
}

.inputbox {
	width: 350px;
	border: 1px solid #ccc;
	margin: 0 auto;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	position: relative;
	transform-origin: top center;
}

.btnsms {
	position: absolute;
	top: -2px;
	right: 10px;
	z-index: 2;
}
</style>
